<template>
    <div class="main">
        <div class="banner">
            <div class="img_box">
                <img src="../../assets/image/broadcast.png" alt="">
            </div>
            <div class="banner_info">
                <h3>直播带货</h3>
                <p>开启电商卖货新时代为企业提供专业稳定的直播带货解决方案</p>
                <div class="banner_info_btn"><button>立即咨询</button></div>
            </div>
        </div>
        <div class="retailers">
          <div class="retailers_header">
              <h3>电商+直播提升商品复购</h3>
              <div class="retailers_header_nav">
                  <ul>
                      <li @click='change(1)' :class='currentIndex==1?"active":""'><span>精准流量</span></li>
                      <li @click='change(2)' :class='currentIndex==2?"active":""'><span>引流获客</span></li>
                      <li @click='change(3)' :class='currentIndex==3?"active":""'><span>在线推荐</span></li>
                      <li @click='change(4)' :class='currentIndex==4?"active":""'><span>优化路径</span></li>
                  </ul>
              </div>
              </div>
              <div class="retailers_con" v-show="currentIndex==1">
                <div class="retailers_con_left">
                    <div>
                        <h3>精准流量</h3>
                        <p>通过设置小程序预告，在各类聊天组内，公众号文章及朋友圈传播，吸引用户预约观看。</p>
                        <div class="retailers_con_left_btn">
                            <button>立即咨询</button>
                        </div>
                    </div>
                </div>
                <div class="retailers_con_right">
                    <img src="../../assets/image/broadcast_menu1.png" alt="">
                </div>
              </div>
              <div class="retailers_con" v-show="currentIndex==2">
                <div class="retailers_con_left">
                    <div>
                        <h3>全场景推广，助力引流获客</h3>
                        <p>小程序直播在微信的多个生态场景展现，助力商家将“流量”变成“销量”</p>
                        <div class="retailers_con_left_btn">
                            <button>立即咨询</button>
                        </div>
                    </div>
                </div>
                <div class="retailers_con_right">
                    <img src="../../assets/image/broadcast_menu2.png" alt="">
                </div>
              </div>
              <div class="retailers_con" v-show="currentIndex==3">
                <div class="retailers_con_left">
                    <div>
                        <h3>边看边买，主播在线推荐</h3>
                        <p>平台培育直播进行精彩的介绍，生动描绘产品特点，提高消费转化</p>
                        <div class="retailers_con_left_btn">
                            <button>立即咨询</button>
                        </div>
                    </div>
                </div>
                <div class="retailers_con_right">
                    <img src="../../assets/image/broadcast_menu4.png" alt="">
                </div>
              </div>
              <div class="retailers_con" v-show="currentIndex==4">
                <div class="retailers_con_left">
                    <div>
                        <h3>内嵌商品，缩短下单路径</h3>
                        <p>底部直接嵌入商品购买地址，用户点击即可下单，促进交易</p>
                        <div class="retailers_con_left_btn">
                            <button>立即咨询</button>
                        </div>
                    </div>
                </div>
                <div class="retailers_con_right">
                    <img src="../../assets/image/broadcast_menu3.png" alt="">
                </div>
              </div>
        </div>
        <div class="promotion_wrap">
            <h3>特色功能</h3>
            <div class="promotion_list">
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/broadcast_icon1.png" alt="">
                    </div>
                    <h4>关联商品</h4>
                </li>
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/broadcast_icon2.png" alt="">
                    </div>
                    <h4>直播抽奖</h4>
                </li>
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/retail_icon2.png" alt="">
                    </div>
                    <h4>开播提醒</h4>
                </li>
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/broadcast_icon3.png" alt="">
                    </div>
                    <h4>直播回放</h4>
                </li>
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/broadcast_icon4.png" alt="">
                    </div>
                    <h4>评论互动</h4>
                </li>
                <li class="promotion_list_con">
                    <div class="promotion_list_imgbox">
                        <img src="../../assets/image/broadcast_icon5.png" alt="">
                    </div>
                    <h4>直播预约</h4>
                </li>
            </div>
            <div class="promotion">
                <button>立即咨询</button>
            </div>
        </div>
        <div class="case_wrap">
            <h3>案例展示</h3>
            <div class="case_con">
                <div class="case_con_img1">
                    <img src="../../assets/image/broadcast_case1.png" alt="">
                </div>
                <div class="case_con_img2">
                    <img src="../../assets/image/broadcast_case2.png" alt="">
                </div>
                <div class="case_con_info">
                    <div>
                        <h4>如何开通微信小程序直播</h4>
                        <p>提供全程指导咨询服务，确保顺利申请开通腾讯直播</p>
                        <div class="case_con_info_btn"><button>立即咨询</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data(){
    return{
      currentIndex : 1
    }
    
  },
  methods:{
     change:function(index){
        this.currentIndex=index;
      }
  }
}
</script>
<style scoped>
    .banner{
        width: 100%;
        height: 540px;
        position: relative;
    }
    .banner img{
        width: 100%;
        height: 540px;
    }
    .banner_info h3{
        font-size: 60px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .banner_info p{
        width: 360px;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin: 10px 0 40px 0;
    }
    .banner_info_btn button{
        width: 185px;
        height: 55px;
        background: #FFFFFF;
        border-radius: 2px;
        border: 1px solid #979797;  
        color: #FF0610;
    }
    .banner_info{
        max-width: 1200px;
        width:1200px;
        position: absolute;
        left:50%;
        top:50%;
        transform: translate(-50%, -50%);
        text-align: left;
    }
    .retail_graphic{
        max-width: 1200px;
        margin: auto;
    }
    .retail_graphic h3{
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #000000;
        padding: 50px 0 30px 0;
    }
    .retail_graphic img{
        width: 1100px;
    }
  .retailers_wrap{
    width: 100%;
    height: 600px;
    background: #F0F4FC;
    box-sizing: border-box;
    padding:20px 30px 30px 30px;
  }
  .retailers{
    max-width: 1200px;    
    margin:auto ;
    margin-top: 50px;
  }
  .retailers_header{
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #D6E4FF;
  }
  .retailers_header h3{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .retailers_header_nav ul{
    display: flex;
  }
  .retailers_header_nav ul li{
    margin: 0 50px;
    height: 80px;
    line-height: 80px;
    /* border-bottom: 3px solid red; */
    cursor: pointer;
  }
  .retailers_con{
    background: #fff;
    padding:30px 25px 30px 50px;
    box-sizing: border-box;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
  }
  .retailers_con_right{
    width: 632px;
    height: 528px;
    /* border: 2px solid #D6E4FF; */
    margin-left: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .retailers_con_right img{
      /* width: 100%; */
      height: 100%;
  }
  .retailers_con_left{
    flex: 1;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding: 40px 0 60px 0;
  }
  .retailers_con_left h3{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .retailers_con_left p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    margin-bottom: 20px;
    margin:25px 0 50px 0;
  }
  .retailers_con_left_btn{
      width: 100%;
  }
  .retailers_con_left_btn button{
    width: 130px;
    height: 44px;
    border-radius: 2px; 
    outline: 0;
    border:0;
    cursor: pointer;
    background: #FF0610;
    /* border: 1px solid #9B9B9B; */
    color: #fff;
  }
  .retailers_con_left_btn button:hover {
    box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
  }
  .promotion_wrap{
    width: 1200px;
    margin: auto;
}
  .promotion_wrap h3{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 70px 0 30px 0px;
  }
  .promotion_list{
      display: flex;
      /* justify-content: center; */
      align-items: center;
      flex-wrap: wrap;
  }
  .promotion_list li{
      width: 24%;
      margin-right: 1.333%;
      height: 200px;
      border-radius: 2px;
      border: 1px solid #EDEEF0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;
  }
  .promotion_list li:nth-child(4n){
      margin-right: 0px;
  }
  .promotion_list_con .promotion_list_imgbox{
      width: 100px;
      height: 100px;
  }
  .promotion_list_con .promotion_list_imgbox img{
      width: 100%;
      height: 100%;
  }
  .promotion_list_con h4{
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin:12px 0;  
  }
  .promotion_list_con p{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
  }
  .promotion button{
    width: 130px;
    height: 44px;
    background: #FF0610;
    border-radius: 2px;
    color: #fff;
    border: 0;
    cursor: pointer;
  }
  .promotion button:hover {
    box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
  }
  .promotion{
    margin-top: 20px;
    margin-bottom: 70px;
  }
  .case_wrap{
    width: 1200px;
    margin: auto;
  }
  .case_wrap h3{
    font-size: 36px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-bottom: 30px;
  }
  .case_con{
    display:flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 95px;
    overflow: hidden;
  }
  .case_con .case_con_img1{
    width: 292px;
    height: 596px;  
    margin-right: 18px;
    }
  .case_con .case_con_img2{
    width: 242px;
    height: 501px;
    margin-right: 64px;
  }
  .case_con .case_con_img1 img,.case_con .case_con_img2 img{
    width: 100%;
    /* height: 100%; */
  }
  .case_con_info{
    width: 492px;
    height: 433px;
    background: #FAFAFA;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 0 60px 0 50px;
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .case_con_info h4{
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .case_con_info p{
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4A4A4A;
    margin: 25px 0 40px 0;
  }
  .case_con_info_btn button{
    width: 130px;
    height: 44px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #FF0000;
    color: #FF0610;
    cursor: pointer;
  }
  .case_con_info_btn button:hover {
    box-shadow: 0px 2px 7px 4px rgba(231, 231, 231, 0.8);
  }
  .active{
    border-bottom: 3px solid #FF0610;
    color: #FF0610;
  }
</style>